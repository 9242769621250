import { Button, Form, FloatingLabel, Modal } from "react-bootstrap";
import { useState } from "react";
import { toastError, toastMessage } from "../../App";

const POST_URL = "https://my-worker.bnnthang.workers.dev/posts";

function PostWriter(props) {
  const [show, setShow] = useState(false);
  const [username, setUsername] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function postNewPost() {
    handleClose();

    toastMessage("Your post is being processed");

    fetch(POST_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        title: title,
        content: content,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          toastError(`Error code ${response.status}`);
        }
      })
      .catch((err) => {
        toastError("Error while adding new post");
        console.log(err);
      });
  }

  return (
    <>
      <Button variant="outline-light" onClick={handleShow}>
        <i className="bi bi-pen"></i>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create a post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FloatingLabel
              controlId="floatingTextarea"
              label="Username"
              className="mb-3"
            >
              <Form.Control
                placeholder="Username"
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              ></Form.Control>
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingTextarea"
              label="Post title"
              className="mb-3"
            >
              <Form.Control
                placeholder="Post title"
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              ></Form.Control>
            </FloatingLabel>
            <Form.Control
              as="textarea"
              placeholder="What is in your mind?"
              style={{ height: "123px" }}
              onChange={(e) => {
                setContent(e.target.value);
              }}
            ></Form.Control>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type="submit" variant="primary" onClick={postNewPost}>
            Post!
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PostWriter;

import "./App.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import TitleBar from "./components/TitleBar/TitleBar";
import PostList from "./components/PostList/PostList";
import { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BASE_URL = "https://my-worker.bnnthang.workers.dev";
const POLL_INTERVAL = 300000;

function comparePosts(post1, post2) {
  if (post1.votes > post2.votes) return -1;
  else if (post1.votes < post2.votes) return 1;
  else return post1.created_time < post2.created_time ? 1 : -1;
}

async function pollPosts() {
  const posts = await fetch(`${BASE_URL}/posts`, {
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .then((postJsons) =>
      postJsons.map((postJson) => JSON.parse(postJson)).sort(comparePosts)
    )
    .catch((err) => {
      toastMessage("Poll failed");
      console.log(err);
    });

  return posts;
}

export function upvotePost(id) {
  fetch(`${BASE_URL}/${id}/upvote`, {
    method: "POST",
  }).catch((err) => console.log(err));
}

export function downvotePost(id) {
  fetch(`${BASE_URL}/${id}/downvote`, {
    method: "POST",
  }).catch((err) => console.log(err));
}

export function toastMessage(msg) {
  toast.info(msg);
}

export function toastError(msg) {
  toast.error(msg);
}

class App extends Component {
  constructor() {
    super();

    this.state = {
      posts: [],
    };
  }

  updatePosts(newPosts) {
    this.setState({
      posts: newPosts,
    });
  }

  componentDidMount() {
    pollPosts().then((posts) => {
      this.updatePosts(posts);
    });

    this.interval = setInterval(() => {
      pollPosts().then((posts) => {
        this.updatePosts(posts);
      });
    }, POLL_INTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div>
        <TitleBar
          updateFn={() => {
            toastMessage("Updating...");
            pollPosts().then((posts) => this.updatePosts(posts));
          }}
        ></TitleBar>

        <PostList postsDataSource={this.state.posts}></PostList>

        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

export default App;

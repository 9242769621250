import Post from "../Post/Post";
import "./PostList.css";

function PostList(props) {
  return (
    <div className="PostList-div">
      {props.postsDataSource.map((post) => (
        <Post
          key={post.id}
          postId={post.id}
          username={post.username}
          title={post.title}
          content={post.content}
          votes={post.votes}
        ></Post>
      ))}
    </div>
  );
}

export default PostList;

import { Card, Button } from "react-bootstrap";
import "./Post.css";
import { upvotePost, downvotePost, toastMessage } from "../../App";

function Post(props) {
  return (
    <Card className="Post-card">
      <Card.Header>
        <i className="bi bi-person-fill"></i>
        {props.username}
      </Card.Header>
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text>{props.content}</Card.Text>
      </Card.Body>
      <Card.Footer className="Post-footer">
        <Button
          className="Vote-button"
          variant="success"
          size="sm"
          onClick={() => {
            toastMessage("Your vote has been sent");
            upvotePost(props.postId);
          }}
        >
          +
        </Button>
        <span className="Vote-span">
          <b>
            {(props.votes > 0 ? "+" : props.votes < 0 ? "-" : "") +
              Math.abs(props.votes)}
          </b>
        </span>
        <Button
          className="Vote-button"
          variant="danger"
          size="sm"
          onClick={() => {
            toastMessage("Your vote has been sent");
            downvotePost(props.postId);
          }}
        >
          -
        </Button>
      </Card.Footer>
    </Card>
  );
}

export default Post;

import {
  Navbar,
  Container,
  Button,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import PostWriter from "../PostWriter/PostWriter";

function TitleBar(props) {
  return (
    <Navbar bg="dark" variant="dark">
      <Container fluid>
        <Navbar.Brand href="/">My App</Navbar.Brand>
        <Navbar.Collapse
          className="justify-content-end"
          style={{ gap: "0.3rem" }}
        >
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>What is in your mind?</Tooltip>}
          >
            <span>
              <PostWriter></PostWriter>
            </span>
          </OverlayTrigger>

          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>Reload posts</Tooltip>}
          >
            <span>
              <Button variant="outline-light" onClick={props.updateFn}>
                <i className="bi bi-arrow-clockwise"></i>
              </Button>
            </span>
          </OverlayTrigger>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default TitleBar;
